import React from "react";
import ReactGA from "react-ga4";

import { BrowserRouter, Routes, Route } from "react-router-dom"

import { Waypoint } from 'react-waypoint';
import AirQLanding from "./pages/AirQLanding";
import Landing from "./pages/Landing";

ReactGA.initialize('G-ZRR6N02WDC');

export default function App() {
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}>
        </Route>
        <Route path="/mobileairq" element={<AirQLanding />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
