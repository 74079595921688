import React from 'react';
// import formSuccess from '../form-success/formSuccess'


export default function emailSub() {
    
    return (
    <form className="w-full mx-auto" name="emailSub" method="POST">
            <div className="flex md:flex-row md:flex-nowrap flex-wrap content-center">
            <input type="hidden" name="form-name" value="emailSub" />
            <input className="md:basis-2/3 bg-white w-full text-gray-700 md:mr-3 mr-0 mb-4 md:mb-0 py-4 px-8 focus:outline-none rounded-full" type="email" name="email" placeholder="Enter Email Address" aria-label="Email Address"/>
            <button className="md:basis-1/3 w-full bg-indigo-700 flex-shrink text-white py-4 rounded-full" type="Submit" value="Join Waitlist">Join Waitlist</button>
            </div>
    </form>
    );

}