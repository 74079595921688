// src/components/About.js

import React from "react";
import './about.css';
import vibesLogo from './vibes-logo.svg'

import EmailSub from "../email-subscribe/EmailSub";
// const element = <FontAwesomeIcon icon={faCoffee} />


export default function About() {
  return (
    <section id="about" className="bg-neutral-950">
      {/* <video autoPlay loop muted id='video' playsinline="true" disablePictureInPicture="true" className="mt-0 lg:-mt-24">
          <source src={backgroundVideo} type='video/mp4'/>
      </video> */}

          

      <div className="container mx-auto flex pt-24 pb-16 flex-col relative text-center h-screen font-mono">
        <div className="lg:flex-grow md:flex-grow flex flex-col text-center">

          <img src={vibesLogo} alt="Vibes Logo" className="mx-auto w-96" />
          
          <h1 className="text-white sm:text-center font-regular tracking-tighter text-3xl mb-4">
            connect through music
          </h1>


          <div className="m-auto border-b-2 border-r-2 border-indigo-300 text-cyan-400 bg-indigo-700 align-center px-2 py-2 w-48">
            <p className="sm:text-center font-bold text-md">
            COMING 2025
            </p>
          </div>

          <div className="px-48 mt-8">
          <EmailSub />
          </div>
      
        
        </div>

      </div>
    
      {/* <div className="md:absolute relative pr-8 md:bottom-36 bottom-8 md:mx-auto mx-0 right-0 bg-neutral-900 font-medium text-white p-4">
          <p>Footage Shot on a DJI Mini 3</p>
          <a href="#featured_b" className="text-sm underline cursor-pointer">Learn More</a>
      </div> */}

    </section>
  );
}